<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        Perfil de puestos
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('cierraForm', 'Cancel')"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pt-5">
        <v-alert
          border="left"
          colored-border
          color="blue lighten-1"
          elevation="2"
        >
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12">
              <p class="ma-0 text-center blue--text font-weight-bold">
                Perfil de puestos
              </p>
            </v-col>
            <v-col
              class="d-flex align-center"
              cols="12"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
              ><p class="ma-0">
                <b>Nombre de puesto:</b>
                <label class="text-decoration-underline">{{
                  item.descr
                }}</label>
              </p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-alert>
        <v-form class="mt-5" ref="form" v-model="valid" lazy-validation>
          <v-card class="pa-5 mb-5">
            <v-row>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="12"
                xl="12"
                class="pa-0"
              >
                <p class="ma-0 text-center blue--text font-weight-bold">
                  Formación academica
                </p>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-autocomplete
                  v-model="form.escolaridad"
                  :items="escolaridad"
                  :loading="loadingForm"
                  :rules="comboBoxPlan"
                  v-on:change="validarEscolaridad"
                  label="Indispensable"
                  hide-selected
                  outlined
                  dense
                  small-chips
                  item-value="id"
                  item-text="descr"
                  background-color="grey lighten-2"
                  color="cyan"
                  clearable
                >
                </v-autocomplete>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <v-autocomplete
                  v-model="form.escolaridadDeseable"
                  :items="escolaridadDeseable"
                  :loading="loadingForm"
                  label="Deseable"
                  hide-selected
                  outlined
                  dense
                  small-chips
                  item-value="id"
                  item-text="descr"
                  background-color="grey lighten-2"
                  color="cyan"
                  clearable
                  :disabled="form.escolaridad ? false : true"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="pa-5 mb-5">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <p class="mb-5 text-center blue--text font-weight-bold">
                  Conocimientos indispensables
                </p>
                <v-combobox
                  v-model="form.conocimientosIndispensables"
                  :items="conocimientos"
                  :loading="loadingForm"
                  :rules="comboBoxPlanCI"
                  v-on:change="validarConocimientos"
                  label="Indispensable"
                  hide-selected
                  outlined
                  dense
                  small-chips
                  item-text="descr"
                  multiple
                  deletable-chips
                  background-color="grey lighten-2"
                  color="cyan"
                >
                </v-combobox>
                <v-expansion-panels
                  accordion
                  v-if="form.conocimientosIndispensables.length > 0"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >Conocimientos seleccionados:
                      <b
                        :style="
                          form.conocimientosIndispensables.length == 5
                            ? 'color: #3baa42'
                            : 'color: #ff4e6f'
                        "
                        >{{ form.conocimientosIndispensables.length }} / 5</b
                      >
                      <v-spacer></v-spacer>
                      {{ ponderacion }} %
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row
                        v-for="(c, index) in form.conocimientosIndispensables"
                        :key="index"
                      >
                        <v-col
                          class="d-flex align-center"
                          cols="12"
                          xs="12"
                          sm="4"
                          md="4"
                          lg="4"
                          xl="4"
                        >
                          [{{ index + 1 }}] {{ c.descr }}
                        </v-col>
                        <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                          <v-text-field
                            type="number"
                            min="1"
                            :rules="rulesValoresNumericos"
                            v-model="c.ponderacion"
                            label="%"
                            outlined
                            dense
                            v-on:change="sumarValoresConocimientosIndisp"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="5" md="5" lg="5" xl="5">
                          <v-autocomplete
                            v-model="c.tiempo"
                            :items="periodos"
                            :loading="loadingForm"
                            label="Tiempo"
                            hide-selected
                            outlined
                            dense
                            small-chips
                            item-value="id"
                            item-text="descripcion"
                            background-color="grey lighten-2"
                            color="cyan"
                            clearable
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <p class="mb-5 text-center blue--text font-weight-bold">
                  Conocimientos deseables (Opcional)
                </p>
                <v-combobox
                  v-model="form.conocimientosDeseables"
                  :items="conocimientosDeseables"
                  :loading="loadingForm"
                  :rules="reglasConocimientosDeseables"
                  label="Deseable"
                  hide-selected
                  outlined
                  dense
                  small-chips
                  item-text="descr"
                  multiple
                  deletable-chips
                  background-color="grey lighten-2"
                  color="cyan"
                  clearable
                  :disabled="form.conocimientosIndispensables ? false : true"
                >
                </v-combobox>
                <v-expansion-panels
                  accordion
                  v-if="form.conocimientosDeseables.length > 0"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >Conocimientos seleccionados:
                      <b
                        :style="
                          form.conocimientosDeseables.length == 3
                            ? 'color: #3baa42'
                            : 'color: #ff4e6f'
                        "
                        >{{ form.conocimientosDeseables.length }} / 3</b
                      >
                      <v-spacer></v-spacer>
                      {{ ponderacionConocimientoDeseable }} %
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row
                        v-for="(c, index) in form.conocimientosDeseables"
                        :key="index"
                      >
                        <v-col
                          class="d-flex align-center"
                          cols="12"
                          xs="12"
                          sm="4"
                          md="4"
                          lg="4"
                          xl="4"
                        >
                          [{{ index + 1 }}] {{ c.descr }}
                        </v-col>
                        <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                          <v-text-field
                            v-model="c.ponderacion"
                            label="%"
                            outlined
                            type="number"
                            min="1"
                            dense
                            v-on:change="sumarValoresConocimientosDeseables"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="5" md="5" lg="5" xl="5">
                          <v-autocomplete
                            v-model="c.tiempo"
                            :items="periodos"
                            :loading="loadingForm"
                            label="Tiempo"
                            hide-selected
                            outlined
                            dense
                            small-chips
                            item-value="id"
                            item-text="descripcion"
                            background-color="grey lighten-2"
                            color="cyan"
                            clearable
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="pa-5 mb-5">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <p class="mb-5 text-center blue--text font-weight-bold">
                  Competencias indispensables
                </p>
                <v-combobox
                  v-model="form.competenciasIndispensables"
                  :items="competencias"
                  :loading="loadingForm"
                  :rules="comboBoxPlanCompI"
                  v-on:change="validarCompetencias"
                  label="Requerido"
                  multiple
                  hide-selected
                  outlined
                  dense
                  small-chips
                  item-text="competencia"
                  deletable-chips
                  background-color="grey lighten-2"
                  color="cyan"
                  clearable
                >
                </v-combobox>
                <v-expansion-panels
                  accordion
                  v-if="form.competenciasIndispensables.length > 0"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >Competencias seleccionadas:
                      <b
                        :style="
                          form.competenciasIndispensables.length == 3
                            ? 'color: #3baa42'
                            : 'color: #ff4e6f'
                        "
                        >{{ form.competenciasIndispensables.length }} / 3</b
                      >
                      <v-spacer></v-spacer>
                      <!-- {{ ponderacionCompetenciaIndispensable }} % -->
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row
                        v-for="(c, index) in form.competenciasIndispensables"
                        :key="index"
                      >
                        <v-col
                          class="d-flex align-center"
                          cols="12"
                          xs="12"
                          sm="9"
                          md="9"
                          lg="9"
                          xl="9"
                        >
                          [{{ index + 1 }}] {{ c.competencia }}
                        </v-col>
                        <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                          <v-text-field
                            v-model="c.ponderacion"
                            label="%"
                            outlined
                            dense
                            type="number"
                            min="1"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <p class="mb-5 text-center blue--text font-weight-bold">
                  Competencias deseables
                </p>

                <v-combobox
                  v-model="form.competenciasDeseable"
                  :items="competenciasDeseables"
                  :loading="loadingForm"
                  :rules="comboBoxPlanCompD"
                  label="Deseable"
                  multiple
                  hide-selected
                  outlined
                  dense
                  small-chips
                  item-text="competencia"
                  background-color="grey lighten-2"
                  color="cyan"
                  deletable-chips
                  :disabled="form.competenciasIndispensables ? false : true"
                >
                </v-combobox>
                <v-expansion-panels
                  accordion
                  v-if="form.competenciasDeseable.length > 0"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >Competencias seleccionadas:
                      <b
                        :style="
                          form.competenciasDeseable.length == 2
                            ? 'color: #3baa42'
                            : 'color: #ff4e6f'
                        "
                        >{{ form.competenciasDeseable.length }} / 2</b
                      >
                      <v-spacer></v-spacer>
                      <!-- {{ ponderacionCompetenciaDeseable }} % -->
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row
                        v-for="(c, index) in form.competenciasDeseable"
                        :key="index"
                      >
                        <v-col
                          class="d-flex align-center"
                          cols="12"
                          xs="12"
                          sm="9"
                          md="9"
                          lg="9"
                          xl="9"
                        >
                          [{{ index + 1 }}] {{ c.competencia }}
                        </v-col>
                        <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                          <v-text-field
                            v-model="c.ponderacion"
                            label="%"
                            outlined
                            dense
                            min="1"
                            type="number"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <!-- <v-divider vertical></v-divider> -->
              <!-- <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <p class="mb-5 text-center blue--text font-weight-bold">
                  Backups 
                </p>

                <v-autocomplete
                  v-model="form.backup1"
                  :items="colaboradores"
                  :loading="loadingForm"
                  :rules="comboBoxPlan"
                  label="¿Quien puede suplir?"
                  outlined
                  dense
                  small-chips
                  item-value="EMPLID"
                  item-text="nombreMostrar"
                  background-color="grey lighten-2"
                  color="cyan"
                  deletable-chips
                >
                </v-autocomplete>
                <v-autocomplete
                  v-model="form.backup2"
                  :items="colaboradores"
                  :loading="loadingForm"
                  :rules="comboBoxPlan"
                  label="¿Quien toma las decisiones?"
                  outlined
                  dense
                  small-chips
                  item-value="EMPLID"
                  item-text="nombreMostrar"
                  background-color="grey lighten-2"
                  color="cyan"
                  deletable-chips
                >
                </v-autocomplete>
              </v-col> -->
            </v-row>
          </v-card>
          <v-card class="pa-5 mb-5">
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <p class="mb-5 text-center blue--text font-weight-bold">
                  Indicadores
                </p>
                <li v-for="(item, index) in indicadores" :key="index">
                  {{ item.DescObjetivo }}
                </li>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <p class="mb-5 text-center blue--text font-weight-bold">
                  Responsabilidades
                </p>
                <div v-if="!showSelect" class="d-flex">
                  <v-text-field
                    v-model="responsabilidad"
                    v-on:change="registrarResponsabilidad"
                    outlined
                    dense
                  ></v-text-field>
                  <v-tooltip top color="teal darken-1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="teal darken-2"
                        @click="registrarResponsabilidad"
                        dark
                      >
                        <v-icon small>mdi-content-save-check-outline</v-icon>
                      </v-btn>
                    </template>
                    <label class="text-uppercase font-weight-bold white--text">
                      Agregar responsabilidad
                    </label>
                  </v-tooltip>
                  <v-tooltip
                    v-if="listResponsabilidades.length > 0"
                    top
                    color="blue darken-2"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        @click="showSelect = true"
                      >
                        <v-icon dark small>mdi-keyboard-return</v-icon>
                      </v-btn>
                    </template>
                    <label class="text-uppercase font-weight-bold white--text">
                      Volver a la lista
                    </label>
                  </v-tooltip>
                </div>
                <div v-else class="d-flex">
                  <v-combobox
                    v-model="form.responsabilidades"
                    :items="listResponsabilidades"
                    :loading="loadingForm"
                    label="Selecciona responsabilidades"
                    hide-selected
                    outlined
                    dense
                    small-chips
                    item-text="descr"
                    multiple
                    deletable-chips
                    background-color="grey lighten-2"
                    color="cyan"
                    clearable
                  >
                  </v-combobox>
                  <v-tooltip top color="blue darken-2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        @click="showSelect = false"
                      >
                        <v-icon>mdi-format-list-bulleted</v-icon>
                      </v-btn>
                    </template>
                    <label class="text-uppercase font-weight-bold white--text">
                      Agregar nueva responsabilidad
                    </label>
                  </v-tooltip>
                </div>
                <v-expansion-panels accordion v-if="form.responsabilidades">
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >Responsabilidades selecionadas:
                      <b
                        :style="
                          form.responsabilidades.length == 0
                            ? 'color: #ff4e6f'
                            : 'color: #3baa42'
                        "
                      >
                        {{ form.responsabilidades.length }}
                      </b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list>
                        <v-list-item
                          v-for="(file, index) in form.responsabilidades"
                          :key="index"
                        >
                          <v-list-item-avatar>
                            <v-icon class="primary" dark>mdi-handshake</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>
                              <li>{{ file.descr }}</li>
                            </v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-tooltip top color="pink lighten-2">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="removerResponsabilidades(index)"
                                >
                                  <v-icon color="pink lighten-2"
                                    >mdi-close</v-icon
                                  >
                                </v-btn>
                              </template>
                              <label
                                class="text-uppercase font-weight-bold white--text"
                              >
                                Remover
                              </label>
                            </v-tooltip>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card>
          <!-- <v-card>
            <v-card-title>
              <p class="mb-5 text-center blue--text font-weight-bold">
                Plan de capacitación
              </p>
            </v-card-title>
            <v-card-text>
              <v-stepper v-model="e6" vertical>
                <v-stepper-step :complete="e6 > 1" step="1" editable>
                  Selecciona responsables
                  <small>para plan de capacitación</small>
                </v-stepper-step>

                <v-stepper-content step="1">
                  <v-card class="mb-5">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="planDesarrollo.responsablePlan"
                            :items="colaboradores"
                            :loading="loadingFormPlan"
                            :rules="comboBoxPlan"
                            label="Responsable del plan"
                            hint="Lider de puesto"
                            hide-selected
                            outlined
                            dense
                            small-chips
                            item-value="EMPLID"
                            item-text="nombreMostrar"
                            background-color="grey lighten-2"
                            color="cyan"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="planDesarrollo.responsableSeguimiento"
                            :items="colaboradores"
                            :loading="loadingFormPlan"
                            :rules="comboBoxPlan"
                            label="Responsable del seguimiento"
                            hint="Capacitación"
                            hide-selected
                            outlined
                            dense
                            small-chips
                            item-value="EMPLID"
                            item-text="nombreMostrar"
                            background-color="grey lighten-2"
                            color="cyan"
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                      <v-btn
                        v-if="
                          planDesarrollo.responsablePlan &&
                          planDesarrollo.responsableSeguimiento
                        "
                        color="primary"
                        @click="guardarPlanDeCapacitacion()"
                      >
                        Continuar
                      </v-btn>
                      <v-btn v-else disabled> Continuar </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-step :complete="e6 > 2" step="2" editable>
                  Fase de inducción
                </v-stepper-step>

                <v-stepper-content step="2">
                  <v-card class="mb-5">
                    <v-data-table
                      :headers="headers"
                      :items="induccionTemasImpartir"
                      :search="buscar"
                      :loading="loadingInfo"
                      item-key="id"
                      loading-text="Cargando temas"
                      class="elevation-1"
                      dense
                      mobile-breakpoint="0"
                      :footer-props="{
                        'items-per-page-text': 'Elementos por página',
                      }"
                      no-data-text="Clic en cargar tema para agregar"
                    >
                      <template v-slot:top>
                        <v-toolbar
                          flat
                          class="rounded-lg rounded-b-0 rounded-t-0"
                        >
                          <v-text-field
                            v-model="buscar"
                            :label="$t('DH360.findTextBox')"
                            class="mt-7 rounded-lg"
                            dense
                            outlined
                            solo-inverted
                            prepend-inner-icon="mdi-table-search"
                          ></v-text-field>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="teal darken-2"
                            elevation="2"
                            class="mx-2 rounded-lg"
                            dark
                            small
                            @click="addTema(1)"
                            >Cargar Tema</v-btn
                          >
                          <v-btn
                            color="teal darken-2"
                            elevation="2"
                            class="mx-2 rounded-lg"
                            dark
                            small
                            @click="openCatalogo = true"
                            >Catálogo de temas
                          </v-btn>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.orden="{ item }">
                        <div align="center">
                          <v-text-field
                            v-model="item.prioridad"
                            :rules="comboBoxPlan"
                            class="rounded-lg"
                            hide-selected
                            outlined
                            dense
                            background-color="grey lighten-2"
                            color="cyan"
                            style="width: 50%"
                            type="number"
                            min="1"
                          ></v-text-field>
                        </div>
                      </template>
                      <template v-slot:item.temas="{ item }">
                        <div align="center">
                          <div class="d-flex">
                            <v-combobox
                              v-model="item.tema"
                              :items="listaTemas"
                              :loading="loadingForm"
                              label="Selecciona un tema"
                              hide-selected
                              outlined
                              dense
                              small-chips
                              item-text="tema"
                              deletable-chips
                              background-color="grey lighten-2"
                              color="cyan"
                              clearable
                            >
                            </v-combobox>
                          </div>
                        </div>
                      </template>
                      <template v-slot:item.horas="{ item }">
                        <div align="center">
                          <v-text-field
                            v-model="item.horas"
                            :rules="comboBoxPlan"
                            class="rounded-lg"
                            hide-selected
                            outlined
                            dense
                            small-chips
                            background-color="grey lighten-2"
                            color="cyan"
                            type="number"
                            min="1"
                          ></v-text-field>
                        </div>
                      </template>
                      <template v-slot:item.responsable="{ item }">
                        <div align="center">
                          <v-autocomplete
                            v-model="item.responsable"
                            :items="colaboradores"
                            :loading="loadingFormPlan"
                            :rules="comboBoxPlan"
                            label="Responsable"
                            hide-selected
                            outlined
                            dense
                            item-value="EMPLID"
                            item-text="nombreMostrar"
                            background-color="grey lighten-2"
                            color="cyan"
                          >
                          </v-autocomplete>
                        </div>
                      </template>
                      <template v-slot:item.tipo="{ item }"
                        >{{ item.tipo }}
                      </template>
                      <template v-slot:item.actions="{ index }">
                        <v-tooltip top color="pink darken-1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              color="pink darken-2"
                              @click="removerTema(index, 1)"
                              small
                              >mdi-close</v-icon
                            >
                          </template>
                          <label
                            class="text-uppercase font-weight-bold white--text"
                          >
                            Remover tema
                          </label>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                    <v-card-actions class="justify-end">
                      <v-btn
                        color="primary"
                        @click="guardarPlanDeCapacitacion()"
                      >
                        Continuar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-step :complete="e6 > 3" step="3" editable>
                  Fase de capacitación
                </v-stepper-step>
                <v-stepper-content step="3">
                  <v-card class="mb-5">
                    <v-data-table
                      :headers="headers"
                      :items="capacitacionTemasImpartir"
                      :search="buscar"
                      :loading="loadingInfo"
                      item-key="id"
                      loading-text="Cargando temas"
                      class="elevation-1"
                      dense
                      mobile-breakpoint="0"
                      :footer-props="{
                        'items-per-page-text': 'Elementos por página',
                      }"
                      no-data-text="Clic en cargar tema para agregar"
                    >
                      <template v-slot:top>
                        <v-toolbar
                          flat
                          class="rounded-lg rounded-b-0 rounded-t-0"
                        >
                          <v-text-field
                            v-model="buscar"
                            :label="$t('DH360.findTextBox')"
                            class="mt-7 rounded-lg"
                            dense
                            outlined
                            solo-inverted
                            prepend-inner-icon="mdi-table-search"
                          ></v-text-field>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="teal darken-2"
                            elevation="2"
                            class="rounded-lg"
                            dark
                            small
                            @click="addTema(2)"
                            >Cargar Tema</v-btn
                          >
                          <v-btn
                            color="teal darken-2"
                            elevation="2"
                            class="mx-2 rounded-lg"
                            dark
                            small
                            @click="openCatalogo = true"
                            >Catálogo de temas
                          </v-btn>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.orden="{ item }">
                        <div align="center">
                          <v-text-field
                            v-model="item.prioridad"
                            :rules="comboBoxPlan"
                            class="rounded-lg"
                            hide-selected
                            outlined
                            dense
                            background-color="grey lighten-2"
                            color="cyan"
                            style="width: 50%"
                            type="number"
                            min="1"
                          ></v-text-field>
                        </div>
                      </template>
                      <template v-slot:item.temas="{ item }">
                        <div align="center">
                          <div class="d-flex">
                            <v-combobox
                              v-model="item.tema"
                              :items="listaTemas"
                              :loading="loadingForm"
                              label="Selecciona un tema"
                              hide-selected
                              outlined
                              dense
                              small-chips
                              item-text="tema"
                              deletable-chips
                              background-color="grey lighten-2"
                              color="cyan"
                              clearable
                            >
                            </v-combobox>
                          </div>
                        </div>
                      </template>
                      <template v-slot:item.horas="{ item }">
                        <div align="center">
                          <v-text-field
                            v-model="item.horas"
                            :rules="comboBoxPlan"
                            class="rounded-lg"
                            hide-selected
                            outlined
                            dense
                            small-chips
                            background-color="grey lighten-2"
                            color="cyan"
                            type="number"
                            min="1"
                          ></v-text-field>
                        </div>
                      </template>
                      <template v-slot:item.responsable="{ item }">
                        <div align="center">
                          <v-autocomplete
                            v-model="item.responsable"
                            :items="colaboradores"
                            :loading="loadingFormPlan"
                            :rules="comboBoxPlan"
                            label="Responsable"
                            hide-selected
                            outlined
                            dense
                            item-value="EMPLID"
                            item-text="nombreMostrar"
                            background-color="grey lighten-2"
                            color="cyan"
                          >
                          </v-autocomplete>
                        </div>
                      </template>
                      <template v-slot:item.tipo="{ item }"
                        >{{ item.tipo }}
                      </template>
                      <template v-slot:item.actions="{ index }">
                        <v-tooltip top color="pink darken-1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              color="pink darken-2"
                              @click="removerTema(index, 2)"
                              small
                              >mdi-close</v-icon
                            >
                          </template>
                          <label
                            class="text-uppercase font-weight-bold white--text"
                          >
                            Remover tema
                          </label>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                    <v-card-actions class="justify-end">
                      <v-btn
                        color="primary"
                        @click="guardarPlanDeCapacitacion()"
                      >
                        Continuar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-step :complete="e6 > 4" step="4" editable>
                  Fase de especialización
                </v-stepper-step>
                <v-stepper-content step="4">
                  <v-card>
                    <v-data-table
                      :headers="headers"
                      :items="especializacionTemasImpartir"
                      :search="buscar"
                      :loading="loadingInfo"
                      item-key="id"
                      loading-text="Cargando temas"
                      class="elevation-1"
                      dense
                      mobile-breakpoint="0"
                      :footer-props="{
                        'items-per-page-text': 'Elementos por página',
                      }"
                      no-data-text="Clic en cargar tema para agregar"
                    >
                      <template v-slot:top>
                        <v-toolbar
                          flat
                          class="rounded-lg rounded-b-0 rounded-t-0"
                        >
                          <v-text-field
                            v-model="buscar"
                            :label="$t('DH360.findTextBox')"
                            class="mt-7 rounded-lg"
                            dense
                            outlined
                            solo-inverted
                            prepend-inner-icon="mdi-table-search"
                          ></v-text-field>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="teal darken-2"
                            elevation="2"
                            class="rounded-lg"
                            dark
                            small
                            @click="addTema(3)"
                            >Cargar Tema</v-btn
                          >
                          <v-btn
                            color="teal darken-2"
                            elevation="2"
                            class="mx-2 rounded-lg"
                            dark
                            small
                            @click="openCatalogo = true"
                            >Catálogo de temas
                          </v-btn>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.orden="{ item }">
                        <div align="center">
                          <v-text-field
                            v-model="item.prioridad"
                            :rules="comboBoxPlan"
                            class="rounded-lg"
                            hide-selected
                            outlined
                            dense
                            background-color="grey lighten-2"
                            color="cyan"
                            style="width: 50%"
                            type="number"
                            min="1"
                          ></v-text-field>
                        </div>
                      </template>
                      <template v-slot:item.temas="{ item }">
                        <div align="center">
                          <div class="d-flex">
                            <v-combobox
                              v-model="item.tema"
                              :items="listaTemas"
                              :loading="loadingForm"
                              label="Selecciona un tema"
                              hide-selected
                              outlined
                              dense
                              small-chips
                              item-text="tema"
                              deletable-chips
                              background-color="grey lighten-2"
                              color="cyan"
                              clearable
                            >
                            </v-combobox>
                          </div>
                        </div>
                      </template>
                      <template v-slot:item.horas="{ item }">
                        <div align="center">
                          <v-text-field
                            v-model="item.horas"
                            :rules="comboBoxPlan"
                            class="rounded-lg"
                            hide-selected
                            outlined
                            dense
                            small-chips
                            background-color="grey lighten-2"
                            color="cyan"
                            type="number"
                            min="1"
                          ></v-text-field>
                        </div>
                      </template>
                      <template v-slot:item.responsable="{ item }">
                        <div align="center">
                          <v-autocomplete
                            v-model="item.responsable"
                            :items="colaboradores"
                            :loading="loadingFormPlan"
                            :rules="comboBoxPlan"
                            label="Responsable"
                            hide-selected
                            outlined
                            dense
                            item-value="EMPLID"
                            item-text="nombreMostrar"
                            background-color="grey lighten-2"
                            color="cyan"
                          >
                          </v-autocomplete>
                        </div>
                      </template>
                      <template v-slot:item.tipo="{ item }"
                        >{{ item.tipo }}
                      </template>
                      <template v-slot:item.actions="{ index }">
                        <v-tooltip top color="pink darken-1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              color="pink darken-2"
                              @click="removerTema(index, 3)"
                              small
                              >mdi-close</v-icon
                            >
                          </template>
                          <label
                            class="text-uppercase font-weight-bold white--text"
                          >
                            Remover tema
                          </label>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                    <v-card-actions class="justify-end">
                      <v-btn
                        color="primary"
                        @click="guardarPlanDeCapacitacion()"
                      >
                        Continuar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>
                <v-stepper-step :complete="e6 > 5" step="5">
                  Validación de formulario
                </v-stepper-step>
                <v-stepper-content step="5">
                  <v-card v-if="!validPlanCapacitacion">
                    <v-card-text
                      class="disabled-text d-flex justify-center align-center"
                    >
                      <v-icon>mdi-alert</v-icon>
                      Complete los datos del plan de capacitación para continuar
                    </v-card-text>
                  </v-card>
                  <v-card v-else>
                    <v-card-text
                      class="disabled-text d-flex justify-center align-center"
                    >
                      <v-icon>mdi-check</v-icon>
                      Puede continuar a registrar el perfil de puesto y plan de
                      capacitación
                    </v-card-text>
                  </v-card>
                </v-stepper-content>
              </v-stepper>
            </v-card-text>
          </v-card> -->
        </v-form>
        <v-dialog
          v-model="openCatalogo"
          max-width="50%"
          transition="dialog-bottom-transition"
          persistent
        >
          <v-card-title class="text-subtitle-2 primary white--text">
            Catalogo - Nuevo tema
            <v-spacer></v-spacer>
            <v-icon small dark @click="openCatalogo = false">mdi-close</v-icon>
          </v-card-title>
          <v-card-text class="pt-5 white">
            <div class="d-flex">
              <v-text-field
                v-model="nuevoTema"
                :rules="comboBoxPlan"
                class="rounded-lg"
                label="Nuevo tema"
                hint="Escribe un nuevo tema"
                v-on:change="registrarTema"
                outlined
                dense
                background-color="grey lighten-2"
                color="cyan"
              ></v-text-field>
              <v-tooltip top color="teal darken-1">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="teal darken-2"
                    @click="registrarTema"
                    dark
                  >
                    <v-icon small>mdi-content-save-check-outline</v-icon>
                  </v-btn>
                </template>
                <label class="text-uppercase font-weight-bold white--text">
                  Agregar tema
                </label>
              </v-tooltip>
            </div>

            <div v-if="nuevosTemas.length > 0">
              <b>Temas por registrar:</b>
              <li v-for="(i, index) in nuevosTemas" :key="index">
                {{ i }}
              </li>
            </div>
            {{ nuevosTemas }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="white">
            <v-spacer></v-spacer>
            <v-btn
              class="rounded-lg"
              color="teal"
              dark
              :loading="loadingTemas"
              @click="guardarTemas"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-dialog>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="rounded-lg"
          color="teal"
          dark
          :loading="loading"
          @click="validar"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";

export default {
  name: "form-perfil-puestos",
  props: ["dialog", "item"],
  components: { SnackBarErrorIncidencias },
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      comboBoxPlan: [
        (value) => !!value || "Este campo es requerido", // Validación de campo requerido
      ],
      comboBoxPlanCI: [
        (value) => !!value || "Este campo es requerido", // Validación de campo requerido
        (value) =>
          (Array.isArray(value) && value.length == 5) ||
          "Selecciona 5 elementos",
      ],
      comboBoxPlanCompI: [
        (value) => !!value || "Este campo es requerido", // Validación de campo requerido
        (value) =>
          (Array.isArray(value) && value.length == 3) ||
          "Selecciona 3 elementos",
      ],
      comboBoxPlanCompD: [
        (value) =>
          value.length == 2 || value.length == 0 || "Selecciona 2 elementos",
      ],
      reglasConocimientosDeseables: [
        (value) =>
          value.length == 3 || value.length == 0 || "Selecciona 3 elementos",
      ],
      rulesValoresNumericos: [
        (value) => !isNaN(value) || "Solo se permiten números",
      ],
      form: {
        escolaridad: "",
        escolaridadDeseable: "",
        conocimientosIndispensables: [],
        conocimientosDeseables: "",
        competenciasIndispensables: [
          {
            id: 50,
            competencia: "Orientación a Resultados",
            ponderacion: null,
            tiempo: null,
          },
          {
            id: 61,
            competencia: "Adaptación al Cambio",
            ponderacion: null,
            tiempo: null,
          },
          {
            id: 49,
            competencia: "Toma de Decisiones",
            ponderacion: null,
            tiempo: null,
          },
        ],
        competenciasDeseable: "",
        tiempoExperiencia: "",
        responsabilidades: [],
        backup1: 0,
        backup2: 0,
      },
      defaultItem: {
        escolaridad: "",
        escolaridadDeseable: "",
        conocimientosIndispensables: "",
        conocimientosDeseables: "",
        competenciasIndispensables: "",
        competenciasDeseable: "",
        Experiencia: "",
        tiempoExperiencia: "",
        responsabilidades: [],
      },
      escolaridad: [],
      escolaridadDeseable: [],
      conocimientos: [],
      conocimientosDeseables: [],
      vistaConocimientosDeseables: [],
      competencias: [],
      competenciasDeseables: [],
      listResponsabilidades: [],
      indicadores: [],
      loading: false,
      loadingForm: true,
      valid: true,
      bandera: 0,
      ponderacion: 0,
      ponderacionConocimientoDeseable: 0,
      ponderacionCompetenciaIndispensable: 0,
      ponderacionCompetenciaDeseable: 0,
      responsabilidad: "",
      showSelect: false,
      periodos: [],
      tab: null,
      tabsPlanDesarrollo: [
        { id: 1, tab: "Responsables" },
        { id: 2, tab: "Fase de inducción" },
        { id: 3, tab: "Fase de capacitación" },
        { id: 4, tab: "Fase de especialización" },
      ],
      colaboradores: [],
      loadingFormPlan: true,

      headers: [
        {
          text: "Orden de prioridad",
          value: "orden",
          align: "center",
          sortable: true,
        },
        {
          text: "Temas a impartir",
          value: "temas",
          align: "center",
          sortable: true,
        },
        {
          text: "Horas de capacitación",
          value: "horas",
          align: "center",
          sortable: true,
        },
        {
          text: "Responsable de la ejecución",
          value: "responsable",
          align: "center",
          sortable: true,
        },
        // {
        //   text: "Link contenido",
        //   value: "actions",
        //   align: "center",
        //   sortable: true,
        // },
        { text: "Acciones", value: "actions", align: "center", sortable: true },
      ],

      planDesarrollo: {
        idPlan: "",
        responsablePlan: 0,
        responsableSeguimiento: 0,
      },
      induccionTemasImpartir: [],
      capacitacionTemasImpartir: [],
      especializacionTemasImpartir: [],
      buscar: "",
      loadingInfo: true,
      e6: 1,
      validPlanCapacitacion: false,
      openCatalogo: false,
      nuevoTema: "",
      listaTemas: [],
      nuevosTemas: [],
      showSelectTemas: false,
      loadingTemas: false,
    };
  },
  created() {},
  async mounted() {
    await this.getColaboradores();
    this.getEscolaridad();
    this.getCatalogoPeriodos();
    this.getConocimientos();
    this.getCompetencias();
    this.getIndicadores();
    this.getResponsabilidades();
    this.getTemasPlanCapacitacion();
    // this.getdatosPuestoNode();
    this.getPerfilPuesto();
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.recargarCompetenciasyConocimientos());
      }, 2000);
    });
  },
  computed: {},

  methods: {
    removerResponsabilidades(index) {
      this.form.responsabilidades.splice(index, 1);
    },
    registrarResponsabilidad() {
      if (this.responsabilidad) {
        if (!this.form.responsabilidades) {
          this.form.responsabilidades = [];
        }
        this.form.responsabilidades.push({
          id: "",
          descr: this.responsabilidad,
        });
      }
      this.responsabilidad = "";
    },

    registrarTema() {
      if (this.nuevoTema) {
        this.nuevosTemas.push(this.nuevoTema);
      }
      this.nuevoTema = "";
    },
    recargarCompetenciasyConocimientos() {
      this.validarCompetencias();
      this.validarConocimientos();
    },
    sumarValoresConocimientosIndisp() {
      this.ponderacion = 0;
      let valor = 0;
      if (this.form.conocimientosIndispensables.length > 0) {
        this.ponderacion = 0;
        this.form.conocimientosIndispensables.forEach((element) => {
          if (element.ponderacion != null) {
            valor += Number(element.ponderacion);
          }
          if (valor > 100) {
            valor -= element.ponderacion;
            element.ponderacion = null;
            this.textoBarra = "Porcentaje permitido debe ser 100%";
            this.colorBarra = "error";
            this.muestraBarra = true;
          }
        });
        this.ponderacion = valor;
      }
    },

    sumarValoresConocimientosDeseables() {
      this.ponderacionConocimientoDeseable = 0;
      let valor = 0;
      if (this.form.conocimientosDeseables.length > 0) {
        this.ponderacionConocimientoDeseable = 0;
        this.form.conocimientosDeseables.forEach((element) => {
          if (element.ponderacion != null) {
            valor += Number(element.ponderacion);
          }
          if (valor > 100) {
            valor -= element.ponderacion;
            element.ponderacion = null;
            this.textoBarra = "Porcentaje permitido debe ser 100%";
            this.colorBarra = "error";
            this.muestraBarra = true;
          }
        });
        this.ponderacionConocimientoDeseable = valor;
      }
    },

    guardar() {
      const headers = { Authorization: localStorage.getItem("token") };
      const params = {
        form: this.form,
        item: this.item,
        // planCapacitacion: {
        //   planDesarrollo: this.planDesarrollo,
        //   induccionTemasImpartir: this.induccionTemasImpartir,
        //   capacitacionTemasImpartir: this.capacitacionTemasImpartir,
        //   especializacionTemasImpartir: this.especializacionTemasImpartir,
        // },
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/set-perfil-puestos`,
            params,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.clean();
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(this.$emit("cierraForm", "OK"));
                }, 1500);
              });
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingInfo = false;
            this.loading = false;
          });
      });
    },

    guardarTemas() {
      this.loadingTemas = true;
      const headers = { Authorization: localStorage.getItem("token") };
      const params = {
        temas: this.nuevosTemas,
        folio: localStorage.getItem("folio"),
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/set-plan-capacitacion-temas`,
            params,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.nuevosTemas = [];
              this.openCatalogo = false;
              this.listaTemas = [];
              this.getTemasPlanCapacitacion();
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingTemas = false;
          });
      });
    },

    editar() {
      const headers = { Authorization: localStorage.getItem("token") };
      const params = {
        form: this.form,
        item: this.item,
        // planCapacitacion: {
        //   planDesarrollo: this.planDesarrollo,
        //   induccionTemasImpartir: this.induccionTemasImpartir,
        //   capacitacionTemasImpartir: this.capacitacionTemasImpartir,
        //   especializacionTemasImpartir: this.especializacionTemasImpartir,
        // },
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/update-perfil-puestos`,
            params,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.clean();
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(this.$emit("cierraForm", "OK"));
                }, 1500);
              });
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingInfo = false;
            this.loading = false;
          });
      });
    },

    validar() {
      this.loading = true;
      this.bandera = 0;
      const validar = this.$refs.form.validate();
      if (validar) {
        let objeto = {};
        for (let i = 0; i < this.form.conocimientosIndispensables.length; i++) {
          objeto = this.form.conocimientosIndispensables[i];
          if (!objeto.ponderacion || !objeto.tiempo) {
            this.bandera = 1; // validamos que si es 1, no continue con el registro
            this.textoBarra =
              "Por favor, agregue el porcentaje y tiempo de sus conocimientos seleccionados";
            this.colorBarra = "error";
            this.muestraBarra = true;
            break;
          }
        }
        for (let j = 0; j < this.form.competenciasIndispensables.length; j++) {
          objeto = this.form.competenciasIndispensables[j];
          if (!objeto.ponderacion) {
            this.bandera = 1; // validamos que si es 1, no continue con el registro
            this.textoBarra =
              "Por favor, agregue el porcentaje de sus Competencias seleccionadas";
            this.colorBarra = "error";
            this.muestraBarra = true;
            break;
          }
        }
        if (this.bandera == 0) {
          // this.validarPlanCapacitacion();
          // if (this.validPlanCapacitacion) {
          if (this.form.id) {
            this.editar();
          } else {
            this.guardar();
            // }
            // } else {
            //   this.textoBarra =
            //     "Complete los datos del plan de capacitación para continuar";
            //   this.colorBarra = "error";
            //   this.muestraBarra = true;
            //   this.loading = false;
          }
        } else {
          this.loading = false;
        }
      } else {
        this.textoBarra = "Por favor, complete los datos requeridos";
        this.colorBarra = "error";
        this.muestraBarra = true;
        this.loading = false;
      }
    },

    clean() {
      this.$nextTick(() => {
        this.form = Object.assign({}, this.defaultItem);
      });
    },

    getEscolaridad() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-escolaridad`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.escolaridad = response.data.data;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },

    getCatalogoPeriodos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-catalogo-periodos`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.periodos = response.data.data;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },

    validarEscolaridad() {
      this.escolaridadDeseable = [];
      this.escolaridad.map((element) => {
        if (element.id > this.form.escolaridad) {
          this.escolaridadDeseable.push(element);
        }
      });
    },

    getConocimientos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-conocimientos`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.conocimientos = response.data.data;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },

    validarConocimientos() {
      let conocimientosSelect = [];
      if (this.form.conocimientosIndispensables) {
        this.form.conocimientosIndispensables.map((element) => {
          conocimientosSelect.push(element.id);
        });
      }
      this.conocimientosDeseables = [];
      this.conocimientos.map((element) => {
        if (!conocimientosSelect.includes(element.id)) {
          this.conocimientosDeseables.push(element);
        }
      });
    },

    getCompetencias() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-competencias`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.competencias = response.data.data;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },

    validarCompetencias() {
      let competenciasSelect = [];
      this.form.competenciasIndispensables.map((element) => {
        competenciasSelect.push(element.id);
      });

      this.competenciasDeseables = [];
      this.competencias.map((element) => {
        if (!competenciasSelect.includes(element.id)) {
          this.competenciasDeseables.push(element);
        }
      });
    },

    getdatosPuestoNode() {
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-datos-puestos/${this.item.id}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status != "EXPSESSION") {
              res(response.data.data);
              this.form.escolaridad =
                response.data.data.escolaridad.idEscolaridad;
              if (response.data.data.conocimientos.length > 0) {
                response.data.data.conocimientos.forEach((element) => {
                  this.form.conocimientosIndispensables.push({
                    id: element.id,
                    descr: element.descr,
                    ponderacion: element.Ponderacion,
                    tiempo: 3,
                  });
                });
                this.sumarValoresConocimientosIndisp();
              }
            }
          });
      }).finally(() => {
        this.loadingCourses = false;
      });
    },

    getPerfilPuesto() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-perfil_puestos/${
              this.item.id
            }/${1}`,
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.form = response.data.data;

              if (this.form.competenciasIndispensables.length == 0) {
                this.form.competenciasIndispensables = [
                  {
                    id: 50,
                    competencia: "Orientación a Resultados",
                    ponderacion: null,
                    tiempo: null,
                  },
                  {
                    id: 61,
                    competencia: "Adaptación al Cambio",
                    ponderacion: null,
                    tiempo: null,
                  },
                  {
                    id: 49,
                    competencia: "Toma de Decisiones",
                    ponderacion: null,
                    tiempo: null,
                  },
                ];
              }
              console.log(this.form);
              // if (this.colaboradores.length > 0) {
              //   this.planDesarrollo.idPlan =
              //     response.data.data.planCapacitacion.id;
              //   this.planDesarrollo.responsablePlan = String(
              //     response.data.data.planCapacitacion.usuario_responsable
              //   );
              //   this.planDesarrollo.responsableSeguimiento = String(
              //     response.data.data.planCapacitacion.usuario_seguimiento
              //   );
              // }

              // this.induccionTemasImpartir =
              //   response.data.data.planCapacitacionTemas.induccion;
              // this.capacitacionTemasImpartir =
              //   response.data.data.planCapacitacionTemas.capacitacion;
              // this.especializacionTemasImpartir =
              //   response.data.data.planCapacitacionTemas.especializacion;

              this.validarEscolaridad();
              this.validarConocimientos();
              this.validarCompetencias();
              this.sumarValoresConocimientosIndisp();
              this.sumarValoresConocimientosDeseables();
            } else if (response.data.status == "VACIO") {
              res(response.data.data);
              console.log("vacio");
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },
    getIndicadores() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-indicadores-por-puesto/${this.item.id}`,
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.indicadores = response.data.data;
            } else if (response.data.status == "VACIO") {
              res(response.data.data);
              console.log("vacio");
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },
    getResponsabilidades() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-responsabilidades`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.listResponsabilidades = response.data.data;
              if (this.listResponsabilidades.length > 0) {
                this.showSelect = true;
              } else {
                this.showSelect = false;
              }
            } else if (response.data.status == "VACIO") {
              res(response.data.data);
              console.log("vacio");
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },

    async getColaboradores() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-all-colaboradores`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.razones);
              var data = response.data.colaboradores;
              this.colaboradores = data;
            }
          })
          .finally(() => {
            this.loadingFormPlan = false;
            this.loadingInfo = false;
          });
      });
    },

    guardarPlanDeCapacitacion() {
      if (this.e6 == 1) {
        this.e6 = 2;
      } else if (this.e6 == 2) {
        this.e6 = 3;
      } else if (this.e6 == 3) {
        this.e6 = 4;
      } else if (this.e6 == 4) {
        // this.validarPlanCapacitacion();
        this.e6 = 5;
      } else if (this.e6 == 5) {
        this.e6 = 1;
      }
    },

    addTema(tipo) {
      if (tipo == 1) {
        this.induccionTemasImpartir.push({
          prioridad: "",
          tema: "",
          horas: "",
          responsable: "",
          tipo: tipo,
          actions: "",
        });
      } else if (tipo == 2) {
        this.capacitacionTemasImpartir.push({
          prioridad: "",
          tema: "",
          horas: "",
          responsable: "",
          tipo: tipo,
          actions: "",
        });
      } else if (tipo == 3) {
        this.especializacionTemasImpartir.push({
          prioridad: "",
          tema: "",
          horas: "",
          responsable: "",
          tipo: tipo,
          actions: "",
        });
      }
    },

    removerTema(index, tipo) {
      this.form.responsabilidades.splice(index, 1);
      if (tipo == 1) {
        this.induccionTemasImpartir.splice(index, 1);
      } else if (tipo == 2) {
        this.capacitacionTemasImpartir.splice(index, 1);
      } else if (tipo == 3) {
        this.especializacionTemasImpartir.splice(index, 1);
      }
    },

    // async validarPlanCapacitacion() {
    //   let numVacios = 0;
    //   this.induccionTemasImpartir.forEach((element) => {
    //     if (
    //       !element.prioridad ||
    //       !element.tema ||
    //       !element.horas ||
    //       !element.responsable
    //     ) {
    //       numVacios++;
    //     }
    //   });
    //   this.capacitacionTemasImpartir.forEach((element) => {
    //     if (
    //       !element.prioridad ||
    //       !element.tema ||
    //       !element.horas ||
    //       !element.responsable
    //     ) {
    //       numVacios++;
    //     }
    //   });
    //   this.especializacionTemasImpartir.forEach((element) => {
    //     if (
    //       !element.prioridad ||
    //       !element.tema ||
    //       !element.horas ||
    //       !element.responsable
    //     ) {
    //       numVacios++;
    //     }
    //   });
    //   if (
    //     !this.planDesarrollo.responsablePlan ||
    //     !this.planDesarrollo.responsableSeguimiento
    //   ) {
    //     numVacios++;
    //   }
    //   if (numVacios > 0) {
    //     this.validPlanCapacitacion = false;
    //     this.textoBarra =
    //       "Complete los datos del plan de capacitación para continuar";
    //     this.colorBarra = "error";
    //     this.muestraBarra = true;
    //   } else {
    //     this.validPlanCapacitacion = true;
    //   }
    // },

    getTemasPlanCapacitacion() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-plan-capacitacion-temas`,
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.listaTemas = response.data.data;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },
  },
};
</script>
<style scoped>
.red--text {
  color: red;
}
