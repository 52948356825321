<template>
  <div>
    <v-dialog v-model="dialog" transition="slide-x-transition" persistent>
      <v-card>
        <v-card-title class="text-subtitle-2 primary white--text">
          Lista de puestos
          <v-spacer></v-spacer>
          <v-icon small dark @click="$emit('cierraDialog', cambios)"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="pt-5">
          <v-data-table
            :headers="headers"
            :items="puestosPorNivel"
            :search="buscar"
            :loading="loadingInfo"
            item-key="id"
            loading-text="Cargando puestos"
            class="elevation-1"
            dense
            mobile-breakpoint="0"
            :footer-props="{ 'items-per-page-text': 'Elementos por página' }"
            no-data-text="No se encontraron registros"
          >
            <template v-slot:top>
              <v-toolbar flat class="rounded-lg rounded-b-0 rounded-t-0">
                <v-text-field
                  v-model="buscar"
                  :label="$t('DH360.findTextBox')"
                  class="mt-7 rounded-lg"
                  dense
                  clearable
                  outlined
                  solo-inverted
                  prepend-inner-icon="mdi-table-search"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn
                  color="teal darken-2"
                  elevation="2"
                  class="rounded-lg"
                  dark
                  small
                  @click="abrirFormulario('nuevo')"
                  >Cargar puestos</v-btn
                >
              </v-toolbar>
            </template>
            <template v-slot:item.area="{ item }">
              <div align="left">[{{ item.id_area }}] {{ item.area }}</div>
            </template>
            <template v-slot:item.nombre="{ item }">
              <div align="left">
                <v-icon
                  :color="item.color"
                  :dark="item.dark == 1 ? true : false"
                  >mdi-circle</v-icon
                >[{{ item.id_familia }}] {{ item.nombre }}
              </div>
            </template>
            <template v-slot:item.DESCR="{ item }">
              <div align="left">[{{ item.id_puesto }}] {{ item.DESCR }}</div>
            </template>
            <template v-slot:item.nivel="{ item }">
              <div align="left">Nivel {{ item.nivel }}</div>
            </template>
            <template v-slot:item.presupuesto="{ item }">
              <div align="right">{{ formatMXN(item.presupuesto) }}</div>
            </template>
            <template v-slot:item.idPuestoPorNivel="{ item }">
              <v-tooltip
                top
                color="green lighten-4"
                v-if="item.idPuestoPorNivel"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="green darken-4" v-bind="attrs" v-on="on">
                    mdi-circle
                  </v-icon>
                </template>
                <label class="text-uppercase font-weight-bold green--text"
                  >Definido</label
                >
              </v-tooltip>
              <v-tooltip top color="grey lighten-1" v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="grey" v-bind="attrs" v-on="on">
                    mdi-circle
                  </v-icon>
                </template>
                <label class="text-uppercase font-weight-bold white--text"
                  >Pendiente</label
                >
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip left color="blue lighten-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="blue darken-4"
                    v-bind="attrs"
                    v-on="on"
                    @click="abrirFormulario(item)"
                  >
                    mdi-pencil-box-outline
                  </v-icon>
                </template>
                <label class="text-uppercase font-weight-bold primary--text"
                  >Editar</label
                >
              </v-tooltip>
              <v-tooltip left color="blue lighten-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="blue darken-4"
                    v-bind="attrs"
                    v-on="on"
                    @click="openForm(item)"
                  >
                    mdi-form-select
                  </v-icon>
                </template>
                <label class="text-uppercase font-weight-bold primary--text"
                  >Perfil de puesto</label
                >
              </v-tooltip>
              <v-tooltip v-if="rol" top color="pink lighten-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="pink darken-4"
                    v-bind="attrs"
                    v-on="on"
                    @click="confirmItem(item)"
                  >
                    mdi-delete-empty-outline
                  </v-icon>
                </template>
                <label class="text-uppercase font-weight-bold pink--text"
                  >Remover</label
                >
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="nuevoPuesto"
      transition="slide-x-transition"
      persistent
      max-width="600px"
    >
      <v-card class="rounded-lg">
        <v-card-title class="text-subtitle-2 primary white--text">
          {{ tipo }} puesto
          <v-spacer></v-spacer>
          <v-icon small dark @click="closeFormPuestos">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="py-5">
          <v-form ref="form" v-model="validPuesto" lazy-validation>
            <div v-if="tipo == 'nuevo'">
              <v-combobox
                v-model="puesto"
                :items="puestos"
                :loading="loadingForm"
                :rules="comboBoxPlan"
                label="-- Seleccione puestos --"
                hide-selected
                outlined
                dense
                item-value="id"
                item-text="name"
                deletable-chips
                small-chips
                color="cyan"
                clearable
                :disabled="loadingForm"
              >
              </v-combobox>
              <v-autocomplete
                v-model="familia"
                :items="familias"
                :loading="loadingForm"
                :rules="comboBoxPlan"
                label="-- Seleccione departamento  --"
                hide-selected
                outlined
                dense
                item-value="id"
                item-text="depto"
                deletable-chips
                small-chips
                color="cyan"
                clearable
                :disabled="depto == 'ALL' ? false : true"
              >
              </v-autocomplete>
            </div>
            <div v-else>
              <p><b>Puesto: </b> {{ puesto }}</p>
              <p><b>Departamento: </b> {{ familia }}</p>
            </div>
            <v-text-field
              v-model="plan"
              :loading="loadingForm"
              :rules="comboBoxNumber"
              label="# de puestos"
              hide-selected
              outlined
              dense
              deletable-chips
              small-chips
              color="cyan"
              clearable
              min="1"
              type="number"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg"
            color="teal"
            dark
            :loading="loading"
            @click="validarPuestos"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <form-perfil-puestos
      v-if="formPerfilPuestos"
      :dialog="formPerfilPuestos"
      :item="itemPuesto"
      @cierraForm="closeForm"
    >
    </form-perfil-puestos>
    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
    <ConfirmDialogReclutamiento
      v-if="showConfirm"
      :showConfirm="showConfirm"
      :question="txtQuestion"
      @cierraConfirm="deleteItem"
    >
    </ConfirmDialogReclutamiento>
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import FormPerfilPuestos from "./FormPerfilPuestos.vue";
import SnackBarErrorIncidencias from "../../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import ConfirmDialogReclutamiento from "../../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";

export default {
  name: "lista-perfil-puestos",
  props: ["dialog", "item", "depto"],
  components: {
    FormPerfilPuestos,
    SnackBarErrorIncidencias,
    ConfirmDialogReclutamiento,
  },
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      puestosPorNivel: [],
      idPuestos: [],
      buscar: "",
      loadingInfo: true,
      loading: false,
      headers: [
        {
          text: "Área",
          value: "area",
          align: "center",
          sortable: true,
        },
        {
          text: "Departamento",
          value: "nombre",
          align: "center",
          sortable: true,
        },
        {
          text: "Puesto",
          value: "DESCR",
          align: "center",
          sortable: true,
        },
        { text: "Nivel", value: "nivel", align: "center", sortable: true },
        {
          text: "# puestos",
          value: "numPuestosPlan",
          align: "center",
          sortable: true,
        },
        {
          text: "Presupuesto",
          value: "presupuesto",
          align: "center",
          sortable: true,
        },
        {
          text: "Perfil de puesto",
          value: "idPuestoPorNivel",
          align: "center",
          sortable: true,
        },
        { text: "Acciones", value: "actions", align: "center", sortable: true },
      ],
      formPerfilPuestos: false,
      itemPuesto: [],
      nuevoPuesto: false,
      validPuesto: true,
      loadingForm: true,
      puesto: "",
      allPuestos: [],
      puestos: [],
      comboBoxPlan: [
        (value) => !!value || "Este campo es requerido", // Validación de campo requerido
      ],
      comboBoxNumber: [
        (value) => /^\d+$/.test(value) || "Solo se permiten números",
        (value) => !!value || "Este campo es requerido", // Validación de campo requerido
      ],
      familia: "",
      familias: [],
      form: {
        idFamilia: "",
        nivel: "",
        idNivel: "",
        plan: "",
        puestos: [],
        id: "",
      },
      plan: "",
      showConfirm: false,
      txtQuestion: "",
      params: {},
      rol: false,
      tipo: "nuevo",
      id: "",
      cambios: 0,
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.familia = this.depto;
    this.getPuestosPorNivel();
    this.getPuestos();
    // this.getFamilias();
    this.getDepartamentos();
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getRoles());
      }, 1500);
    });
  },
  methods: {
    getRoles() {
      let roles = "'AdmonEscalera','Champion'";
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-roles/${roles}`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.rol = response.data.data;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },
    formatMXN(cadena) {
      if (cadena) {
        return cadena.toLocaleString("es-MX", {
          style: "currency",
          currency: "MXN",
        });
      } else {
        return "--";
      }
    },
    getDepartamentos() {
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-departamentos-zentric/${this.item["id_area"]}`,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.familias = response.data.data;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },
    getPuestosPorNivel() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-puestos-por-niveles/${this.item["id_area"]}/${this.familia}/${this.item["nivel"]}`,
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.puestosPorNivel = response.data.data;
              this.puestosPorNivel.map((element) => {
                this.idPuestos.push(element.id_puesto);
              });
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingInfo = false;
          });
      });
    },
    openForm(item) {
      this.formPerfilPuestos = true;
      this.itemPuesto = { id: item.id_puesto, descr: item.DESCR };
    },
    closeFormPuestos() {
      this.nuevoPuesto = false;
      this.clean();
      this.getPuestosPorNivel();
      this.getPuestos();
    },
    confirmItem(item) {
      this.params = {
        id: item.id,
        idPuestoPorNivel: item.idPuestoPorNivel,
        modal: "listaPuestos",
        correo: localStorage.getItem("correo"),
      };
      this.showConfirm = true;
      this.txtQuestion =
        "¿Desea remover este puesto de la lista? \n \n Puesto: " + item.DESCR;
    },

    deleteItem(respuesta) {
      if (respuesta) {
        const headers = { Authorization: localStorage.getItem("token") };
        return new Promise((res) => {
          axios
            .post(
              `${this.$store.getters.getHostNode}/api/delete-puestos-por-niveles`,
              this.params,
              { headers }
            )
            .then((response) => {
              if (response.data.status == "OK") {
                res(response.data.data);
                this.textoBarra = response.data.message;
                this.colorBarra = "success";
                this.muestraBarra = true;
                this.clean();
                this.getPuestosPorNivel();
                this.cambios++;
              } else if (response.data.status == "EXPRESSION") {
                deleteToken();
              } else {
                res([]);
              }
            })
            .finally(() => {
              this.showConfirm = false;
            });
        });
      } else {
        this.params = {};
      }
      this.showConfirm = false;
    },
    clean() {
      this.puestosPorNivel = [];
      this.loadingInfo = true;
      this.form.idFamilia = "";
      this.form.nivel = "";
      this.form.plan = "";
      this.form.puestos = [];
      this.puesto = "";
      this.puestos = [];
      this.idPuestos = [];
      this.allPuestos = [];
      this.familia = this.depto;
      this.loadingForm = true;
    },
    closeForm(response) {
      this.formPerfilPuestos = false;
      if (response == "OK") {
        this.clean();
        this.getPuestosPorNivel();
        this.cambios++;
      }
    },

    validarPuestos() {
      // if (this.puesto.length > 0 && this.familia && this.plan > 0) { //para opcion multiple
      if (this.puesto && this.familia && this.plan > 0) {
        //por puesto, 1 a 1
        this.loading = true;
        if (this.tipo == "nuevo") {
          if (this.depto == "ALL") {
            this.form.idFamilia = this.familia;
          } else {
            this.form.idFamilia = this.depto;
          }
          this.form.idArea = this.item.id_area;
          this.form.nivel = this.item.nivel;
          this.form.idNivel = this.item.id;
          this.form.plan = this.plan;
          // this.puesto.map((element) => {
          //   this.form.puestos.push(element.id);
          // });
          this.form.puestos.push(this.puesto.id);
          this.guardarPuestos();
        } else {
          this.form.id = this.id;
          this.form.plan = this.plan;
          this.editarPuestos();
        }
      } else {
        this.textoBarra = "Complete el formulario para continuar";
        this.colorBarra = "error";
        this.muestraBarra = true;
      }
    },

    guardarPuestos() {
      const headers = { Authorization: localStorage.getItem("token") };
      const params = {
        form: this.form,
        correo: localStorage.getItem("correo"),
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/set-puestos-escaleras-crecimiento`,
            params,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.nuevoPuesto = false;
              this.clean();
              this.getPuestosPorNivel();
              this.getPuestos();
              this.cambios++;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingInfo = false;
            this.loading = false;
          });
      });
    },

    editarPuestos() {
      const headers = { Authorization: localStorage.getItem("token") };
      const params = {
        form: this.form,
        idArea: this.item["id_area"],
        correo: localStorage.getItem("correo"),
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/update-puestos-escaleras-crecimiento`,
            params,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.nuevoPuesto = false;
              this.clean();
              this.getPuestosPorNivel();
              this.getPuestos();
              this.cambios++;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingInfo = false;
            this.loading = false;
          });
      });
    },
    getPuestos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-puestos-zentric/${this.item["id_area"]}`,
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.puestos = response.data.data;
              // this.allPuestos.map((element) => {
              //   {
              //     if (!this.idPuestos.includes(element.id)) {
              //       this.puestos.push(element);
              //     }
              //   }
              // });
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },
    getFamilias() {
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-familias/${this.item["id_area"]}`,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.familias = response.data.data;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },

    abrirFormulario(item) {
      this.nuevoPuesto = true;
      if (item != "nuevo") {
        this.tipo = "editar";
        this.puesto = item.DESCR;
        this.familia = item.nombre;
        this.plan = item.numPuestosPlan;
        this.id = item.id;
      } else {
        this.tipo = "nuevo";
        this.puesto = "";
        this.familia = this.depto;
        this.plan = "";
        this.id = "";
      }
    },
  },
};
</script>
